.penguin_slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.penguin_slider {
  -ms-overflow-style: none;
}

.penguin_slider_arrow_button {
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
  background-color: white;
  opacity: 0.6;
}

.penguin_slider_arrow_button:hover {
  background-color: black;
  color: white;
  opacity: 0.8;
}

.penguin_slider_arrow_icon {
  /* transform: translateY(15px); */
}

.inf_dummy_carousel_card{
  height: var(--inf-bottom-carousel-card-height, 74px);
  background-color: #31323A;
  border-radius: 2px;
}

.inf_carousel_card{
  cursor: pointer;
  text-align: left;
  height: var(--inf-bottom-carousel-card-height, 74px);
  border-radius: 2px;
  overflow: hidden;
  background-color: white;
  /* border: 1px solid var(--dark-border-color) */
}

.inf_carousel_card_image_container{
  height: 100%;
  /* flex: 1; */
  /* width: 100px; */
  aspect-ratio: 1.5/1;
  overflow: hidden;
  border-right: 1px solid var(--dark-border-color)
}

.inf_carousel_card:hover img{
  scale: 1.05;
  transition: scale 0.5s ease-in-out;
}

.inf_carousel_card_image_container img{
  /* object-fit: contain; */
}

.inf_carousel_group_sku_count{
  visibility: hidden;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--inf-theme-gray-200);
  border: 1px solid var(--light-border-color);
  border-radius: 2px;
}

.inf_carousel_card:hover{
  border: 1px solid #0078ff;
}

.inf_carousel_card:hover .inf_carousel_group_sku_count{
  visibility: visible;
}

.inf_carousel_card_sku_count_placeholder{
  display: flex;
  align-items: center;
  padding: 0px 8px 8px;
  color: #A7A8B2;
}

.inf_carousel_card:hover .inf_carousel_card_sku_count_placeholder{
  display: none;
}

select.inf_carousel_card_sku_selector {
  display: none;
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
}

.inf_carousel_card:hover .inf_carousel_card_sku_selector_container{
  position: relative;
  display: inline-block;
  border-top: 1px solid var(--dark-border-color);
}
.inf_carousel_card:hover .inf_carousel_card_sku_selector_container::before{
  content: "\25BE"; /* Custom arrow */
  position: absolute;
  height: 100%;
  width: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #676878;
  right: 0px;
}

.inf_carousel_card_sku_selector_container{
  cursor: pointer;
  background-color: #F5F6F8;
}

.inf_carousel_card_sku_selector_container select {
  padding-right: 24px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inf_carousel_card:hover .inf_carousel_card_sku_selector_container:hover:before{
  color: #0078ff;
  background-color: #E6E9EE
}



.inf_carousel_card:hover .inf_carousel_card_sku_selector{
  display: block;
}

.inf_carousel_action_buttons{
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: white;
  color: #323338;
  font-size: 16px;
  cursor: pointer;
}