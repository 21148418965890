.nonactive_tab_pill_styles:hover {
	color:#007BFF !important;
}
.active_tab_pill_styles{
	background-color: rgba(4, 40, 68, 0.7) !important;
	color:white !important;
}
.active_tab_pill_styles:hover {
	color:white !important;
}

.on_hover_blur_image:hover .x1{
	margin: 0!important;
}
.on_hover_blur_image:hover .x1 .x2{
	display: flex !important;
}

.in_progress_style{
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0px;
    color: #A7A8B2;
}

.preview_done_style{
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0px;
    color: #23C16B;
}

.gpu_cpu_info{
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	color: #A7A8B2;
}

.border_table ,.border_td ,.border_th{
	border: 1px solid black;
	text-align: center;
}
.padding_th{
	padding:10px 0px;
	border:2px solid black;
	min-width: 80px;
}
.max_width_td{
	max-width: 150px !important;
}

.boq_schedule_dropdown_menu{
    width:calc(100% - 40px);
    max-height:500px;
    overflow-y:auto;
}
.multiSelectContainer ul{
	max-height:450px !important;
	height:auto;
}

.multiSelectContainer li:hover{
	background-color: #E6E9EE;
	color: rgba(50, 51, 56, 1);
}

.displayNone{
	display: block;
}


/* for_parameter */

.align_center{
	display: flex;
	align-items: center;
}

.commom_style{
	display: flex;
	align-items: center;
	font-size: 16px;
}
.common_input_style{
	font-size: 16px;
	width:100%;
	flex:5
}
.common_label_style{
	margin-right: 20px;
	flex:1
}

/* for Voilation modal */

.th_style_for_voilation{
	text-transform: capitalize;
	padding: 10px 5px;
}
.td_style_for_voilation{
	padding: 10px 5px;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncated_texture_name{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;
}

.truncated_texture_name2{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 180px;
}

.mcp_color {
	width: 21px; /* Set the width of the input */
	height: 100%; /* Set the height of the input */
	padding: 0; /* Remove any padding */
	border: none; /* Remove any borders */
	outline: none; /* Remove the default focus outline */
	appearance: none; /* Remove browser default styling */
	cursor: pointer;
  }

  /* Additional CSS to target specific browsers */
  .mcp_color::-webkit-color-swatch-wrapper {
	padding: 0; /* Remove padding for WebKit browsers (Safari, Chrome) */
	border: none; /* Remove border for WebKit browsers (Safari, Chrome) */
  }

  .mcp_color::-moz-color-swatch {
	padding: 0; /* Remove padding for Firefox */
	border: none; /* Remove border for Firefox */
  }

  .color_hex_code {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.62px;
	text-align: left;
	padding: 0 5px;
    border: solid 1px;
    cursor: pointer;
	height: 100%;
	border-left: none;
	display: flex;
	align-items: center;
  }

/* for Voilation modal */

.checkbox_background_hover{
	pointer-events: auto;
}
.checkbox_background_hover_before{
	pointer-events: none;
	height:30px;
	width:30px;
	border-radius:50%;
	display:flex;
	align-items:center;
	justify-content:center
}
.checkbox_background_hover_before:hover{
	background-color: #d9d9d9;
	opacity: .8;
}

.special_text {
	cursor: pointer;
	color:#007BFF;
	font-weight: 500;
}

.layerset_row {
	padding: 0px 16px 0px 16px;
}

.layerset_row:hover {
	background-color: #F5F6F8;
}

.layerset_row:hover .invisible_icon {
	visibility: visible;
}

.invisible_icon {
	visibility: hidden;
}

.active_layerset {
	background-color: #D3EDFD !important;
}

.table_style { 
	flex-direction: column; 
	width: 100% !important;
} 

.tr_style {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
}

.th_style {
	flex:1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	font-weight: unset;
	/* border-right: 1px solid #f2f4f6; */
	color:#627386;
}

.td_style {
	flex:1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	color:#22272e;
	width:100%;
	overflow-x:auto;
	white-space: nowrap;
	border-right: 1px solid #f2f4f6;
	text-transform:capitalize;
}

.input_container_publish_sheet{
	border: 1px solid #c5c7cf;
	padding-left: 12px;
	height: 32px;
	background-color: white;
}

.add_option_publish{
	background: rgba(16, 112, 202, 0.08);
	border-radius: 3px;
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.add_option_container_publish{
	display: flex;
	align-items: center;
	padding: 0px 6px;
	border-left: 1px solid #c5c7cf;
	height: 100%;
}

.vendor_container{
	width: 100%;
	padding-right: 12px;

}

.vendor_present_sku_container{
	width: 70%;
	/* padding-right: 12px; */
	display: flex;
	flex-wrap: wrap;
}
.checkbox_list_item{
	justify-content: flex-start;
	height: 36px;
	font-size: 14px;
}

.simple_white_button{
	padding: 6px 12px;
	font-size: 12px;
	font-weight: 500;
	border: 1px solid #c5c7cf;
	background-color: white;
	cursor: pointer;
}

.bucket_selection{
	padding: 20px;
	/* border: 1px solid; */
	background-color: white;
	margin-bottom: 20px;
	border-radius: 4px;
}

.sku_in_bucket{
	padding: 20px;
}

.sku_list_container{
	display: flex;
	margin: 0px -6px 0px -6px;
	flex-wrap: wrap;
	max-height: 250px;
	overflow: auto;
}

.sku_image{
	height: 93px;
	width: 126px;
	gap: 32px;
	/* margin-right: 12px; */
	/* margin-left: 6px; */
	/* margin-right: 6px; */
}

.sku_card_container{
	padding: 0px 6px;
	text-align: center;
}

.vendor_modal_regular_text{
	white-space: pre-wrap;
}

.active_possible_type{
	background-color: rgba(16, 112, 202, 0.09);
	color: rgba(16, 112, 202);
	padding: 6px 12px;
	margin-right: 16px;
	border-radius: 4px;
	cursor: pointer;
}

.possible_type{
	padding: 6px 12px;
	margin-right: 16px;
	border-radius: 4px;
	cursor: pointer;
}

.shortcuts_table_header{
	font-size: 14px;
	color: #A7A8B2;
	font-weight: 400;
}
.percentage-symbol{
	/* content: '%'; */
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}

/* Common styles for all cards */
.render-card-container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: flex-start;
	padding-left: 16px;
	padding-right: 16px;
}


.render-card:hover {
	border: 1px solid #0078ff;
}

.render-pencil {
	display: none;
}

.render-card:hover .render-pencil {
	display: inline-block;
}

.render-card {
	min-width: 200px;
	/* Set your desired minimum width */
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	flex-basis: calc(33.33% - 16px);
}

/* Media query for two render-cards per row */
@media (max-width: 1199px) {
	.render-card {
		flex-basis: calc(50% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for one render-cards per row */
@media (max-width: 992px) {
	.render-card {
		flex-basis: 100%;
		/* Adjust based on the gap */
	}
}


.presentation-template-card-container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: flex-start;
	padding-left: 16px;
	padding-right: 16px;
}


.presentation-template-card:hover {
	outline: 1px solid #0078ff;
}

.presentation-template-card {
	min-width: 200px;
	/* Set your desired minimum width */
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	cursor: pointer;
	flex-basis: calc(50% - 16px);
}

/* Media query for two presentation-template-cards per row */
@media (max-width: 1199px) {
	.presentation-template-card {
		flex-basis: calc(50% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for two presentation-template-cards per row */
@media (max-width: 991px) {
	.presentation-template-card {
		flex-basis: calc(50% - 16px);
		/* flex-basis: 100%; */
		/* Adjust based on the gap */
	}
}

.presentation_image_card:hover {
	border: 1px solid #0078ff;
}

.presentation_image_card{
	min-width: 200px;
	/* Set your desired minimum width */
	border: 1px solid black;
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	cursor: pointer;
	flex-basis: calc(25% - 16px);
}

.focus_input:focus{
	border: 1px solid #0078ff
}

/* Media query for three presentation_image_cards per row */
@media (max-width: 1199px) {
	.presentation_image_card {
		flex-basis: calc(33% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for two presentation_image_cards per row */
@media (max-width: 991px) {
	.presentation_image_card {
		flex-basis: calc(50% - 16px);
		/* flex-basis: 100%; */
		/* Adjust based on the gap */
	}
}


.slider_post_processing {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider_post_processing:hover {
    opacity: 1;
  }
  
  .slider_post_processing::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    background:  #676878;
    cursor: pointer;
    border-radius: 50%;
  }
/* .fire_render_button{
	flex: 0 0 calc( 33.3% - 16px );
	text-align: center;
} */

.invalid_parameter_input{
	/* box-shadow: inset 0 0 2px var(--red_default); */
	outline: 1px solid var(--red_default);
	outline-offset: -2px;
}

.unselected_schedule_field_list_item{
	border-radius: 4px;
}
.unselected_schedule_field_list_item:hover{
	background-color: #cccccc;
}

.single_row_parent{
	height: 52px;
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	box-shadow: inset 0 -1px 0 0 #E4E7EB;
	cursor: pointer
}

.single_row_child .single_row_parent{
	box-shadow: none;
	border-left: 1px solid #C5C7CF;
	padding: 0px;
}

.single_row_child .single_row_parent::before{
	content: '-';
	color: #C5C7CF;
}

.single_row_child{
	padding-left: 24px;
}

.single_row_child .single_row_parent:last-child{
	border-bottom: 1px solid #C5C7CF;
}

.material_properties::-webkit-scrollbar-track{
    background-color: transparent;
    box-shadow: none;
}

.material_properties::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 4px;
}

.infurnia-radio .ant-radio-button-wrapper{
	flex: 1;
	text-align: center;
}

.layer-grid-item {
	padding: 10px; /* Padding for each cell */
}

.first-layer-grid-item {
	border-left: 1px solid black;
}

.layer_overriden_item {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #3686F7;
}

.layer_default_item {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #E6E9EE;
}

.layer_color_item {
font-family: DM Sans;
font-size: 11px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #676878;
}

.color_input_layer {
	width: 16px; /* Set the width of the input */
	height: 16px; /* Set the height of the input */
	padding: 0; /* Remove any padding */
	border: none; /* Remove any borders */
	outline: none; /* Remove the default focus outline */
	appearance: none; /* Remove browser default styling */
  }
  
  /* Additional CSS to target specific browsers */
  .color_input_layer::-webkit-color-swatch-wrapper {
	padding: 0; /* Remove padding for WebKit browsers (Safari, Chrome) */
	border: none; /* Remove border for WebKit browsers (Safari, Chrome) */
  }
  
  .color_input_layer::-moz-color-swatch {
	padding: 0; /* Remove padding for Firefox */
	border: none; /* Remove border for Firefox */
  }

  .styled-number-input {
	/* Ensure arrows are always visible */
	-moz-appearance: textfield;
	appearance: textfield;
	/* Hide the default number input */
	width: 4em;
	margin-right: 5px;
	overflow: hidden;
  }
  
  .styled-number-input::-webkit-inner-spin-button {
	opacity: 1;
  }
  .styled-number-input::-webkit-outer-spin-button {
	/* Show arrows */
	opacity: 1;
  }
  

.single_ss_hov:hov {
	background: transparent !important;
}

.layer-grid-item {
	border-right: 1px solid black; /* Right border for each cell except the last one in a row */
}

.layer-grid-container > div .layer-grid-item:last-child {
	border-right: none;
}

.layer-grid-container > div:first-child .layer-grid-item {
	border-top: 1px solid black; /* Top border for cells in the first row */
}

.layer-grid-container > div:last-child .layer-grid-item {
	border-bottom: 1px solid black; /* Bottom border for cells in the last row */
}

.sheet_card_item {
	border-radius: 4px;
    transition: background-color 0.3s;
}

.sheet_card_item.selected {
	background: linear-gradient(0deg, rgba(0, 120, 255, 0.1), rgba(0, 120, 255, 0.1)),
	linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
	border: 1px solid #0078FF;
}

.sheet_card_item.selected:hover {
	border: 1px solid #0078FF;
}

.sheet_card_item:hover {
	border: 1px solid #C5C7CF;
}

.invalid_input_cam{
    border: 1px solid red
}

.suggestion-list-cutlist{
	width: 60%;
	position: absolute;
	top: 32px;
	right: 0;
	text-align: left;
	font-size: 12px;
	color: black;
	padding: 4px;
	cursor: pointer;
	margin-top: 8px;
	margin-left: 8px;
	border: 1px solid #0078ff;
  }
  
  .single-suggestion-cutlist{
	height: 28px;
	display: flex;
	align-items: center;
  }
  
  .single-suggestion-cutlist:hover{
	background-color: rgba(24,144,255,.06);
  }

  .unsetModalContent{
	background-color: transparent;
	border: none;
  }
.cam_applicator_card{
	padding: 12px 12px 0px 12px;
	background: white;
	min-width: 0px;
	cursor: pointer;
	border-radius: 4px;
	display: flex ;
	flex-flow: column ;
	gap: 8px
}

.infurnia_panel_modal_card_row{
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	border: solid #C5C7CF;
	border-width: 0px 1px 0px 1px;
	justify-content: space-between;
	padding: 6px 6px;
}
.release_notes_feature_heading{
	font-weight: 500;
}

.release_notes_feature_heading:hover{
	font-weight: 600;
}

.infurnia_panel_modal_card_header{
	width: 100%;
	height: 54px;
	border-width: 0px 1px 0px 1px;
	display: flex;
	margin-top: 16px;
	padding: 8px 12px;
	background-color: #FFFFFF;
	border-color: #C5C7CF;
	align-items: center;
	justify-content: space-between;
}

.connector_set_modal_filters{
	background-color: white;
	padding: 16px;
	/* margin: calc( -1 * var(--inf-modal-body-padding, 16px) ) 0px calc( -1 * var(--inf-modal-body-padding, 16px) ) calc( -1 * var(--inf-modal-body-padding, 16px) ); */
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 280px;
}

.connector_set_modal_filters_container{

}

.connector_set_modal_filters_footer{
	display: flex;
	gap: 32px;
	justify-content: space-between;
	margin-top: auto;
}

.connector_set_modal_data{
	flex: 1;
	min-height: 250px;
	overflow: auto;
}

.connector_set_modal_connector_card{
	border-radius: 8px;
	background-color: #0078FF0D;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.connector_set_modal_connector_card_footer{
	padding: 8px 16px;
	color: #0078ff;
}

.connector_set_modal_data_container{
	flex: 1;
	min-width: 0px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.connector_set_modal_data_container_header{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.connector_set_modal_data_container_nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
}

.connector_set_modal_data_container_nav_info{
	display: flex;
	gap: 16px;
	padding: 4px 8px;
	white-space: nowrap;
	font-size: 12px;
}

.connector_set_selection_card{
	display: flex;
	gap: 4px;
	background: white;
	border-radius: 6px;
	overflow: hidden;
	padding: 4px 12px;
	cursor: pointer;
}

.confirm_scenarios_card{
	background-color: white;
	padding: 24px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	border: 1px solid #A7A8B2;
	cursor: pointer;
}

#board_layout_view{
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: absolute;
	top: var(--infurnia-navbar-height, 52px);
	height: calc(100% - var(--infurnia-navbar-height, 52px));
	width: 100%;
	padding: 0.75rem;
	background-color: #eceff1;
	z-index: 10;
	text-align: left;
}

.board_layout_info_card{
	border: 1px solid var( --light-border-color, #C5C7CF);
	padding: 4px 8px;
	border-radius: 6px;
	background-color: white;
}

.inf_cam_flow_modular_units_filter_control .inf_cam_flow_modular_units_filter_info{
	display: none;
}

.masonry-grid {
	display: flex;
	width: auto;
}

.masonry-grid-column {
	padding-left: 16px;
	background-clip: padding-box;
}

.masonry-grid-column > div {
	margin-bottom: 16px;
}